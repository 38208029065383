<template>
  <div class="v-project-join">
    <div class="notice-wraper" style="background:transparent;">
      <!-- 提示 -->
      <div class="notice">
        <span
          >为了帮您迅速找到合适的新药临床试用机会，我们需要采集您的个人基本信息，并会严格根据平台</span
        ><span
          ><a
            href="https://shulan-hospital.shulan.com/useragreement/shulan_patient_app_private_policy.html"
            >《用户服务协议》</a
          ></span
        ><span>和</span
        ><a
          href="https://shulan-hospital.shulan.com/useragreement/shulan_patient_app_policy.html"
          >《隐私权政策》</a
        >保护您提交的信息。希望您能理解并请抽出几分钟时间，回答以下问题。您所提交的信息仅用做评估筛选及联系使用。
      </div>
    </div>

    <!-- 同意 -->
    <radio-input
      title="在报名之前，您是否同意招募机构采集您的个人信息，并筛选适合的临床项目？"
      :items="[
        { optionContent: '同意', id: 1 },
        { optionContent: '不同意', id: 2 },
      ]"
      @change="agreeRadioOnChange"
    />
    <div class="height24"></div>

    <div v-show="agreeStatus == 1">
      <!-- 姓名 -->
      <text-input
        title="姓名"
        placeholder="请输入姓名"
        @change="textOnChange"
        field="name"
      />
      <div class="height24"></div>

      <!-- 手机号 -->
      <text-input
        title="手机号码"
        placeholder="请输入手机号码"
        @change="textOnChange"
        field="phone"
      />
      <div class="height24"></div>

      <!-- 家属的手机号码 -->
      <text-input
        title="家属的手机号码"
        :isRequred="false"
        placeholder="请输入家属的手机号码"
        @change="textOnChange"
        field="family_phone"
      />
      <div class="height24"></div>

      <radio-input
        title="性别"
        :items="[
          { optionContent: '男', id: 1 },
          { optionContent: '女', id: 2 },
        ]"
        @change="genderRadioOnChange"
      />
      <div class="height24"></div>

      <!-- 出生日期 -->
      <div @click="birthdayOnClick()">
        <date-input
          title="出生日期"
          placeholder="请输入出生日期"
          :value="birthday"
        />
      </div>
      <div class="height24"></div>

      <!-- 所在省份和地区 -->
      <div @click="currentAreaOnClick()">
        <location-input
          title="所在省份和地区（省/市/区）"
          placeholder="请选择地区"
          :value="areaAddress"
        />
      </div>
      <div class="height24"></div>

      <!-- 自定义题目 -->
      <div class="" v-for="(item, index) in questionList" :key="item.id">
        <!-- subjectType null 类型 0-单选,1-多选,2-填空 地区3 日期4 -->
        <!-- 单选 -->
        <radio-input
          v-if="item.subjectType == 0"
          :title="item.subjectContent"
          :items="item.subjectOptions"
          @change="radioOnChange"
          :index="index"
          :isRequred="item.isRequired"
        />

        <!-- 多选 -->
        <checkbox-input
          v-else-if="item.subjectType == 1"
          :title="item.subjectContent"
          :items="item.subjectOptions"
          @change="checkboxOnChange"
          :index="index"
          :isRequred="item.isRequired"
        />

        <!-- 填空 -->
        <text-input
          v-else-if="item.subjectType == 2"
          :title="item.subjectContent"
          placeholder="请输入"
          @change="textOnChange"
          :index="index"
          :isRequred="item.isRequired"
        />

        <!-- 地区 -->
        <div v-else-if="item.subjectType == 3" @click="areaOnClick(index)">
          <location-input
            :title="item.subjectContent"
            :value="item.subjectResult"
            placeholder="请选择地区"
            :index="index"
            :isRequred="item.isRequired"
          />
        </div>

        <!-- 日期 -->
        <div v-else-if="item.subjectType == 4" @click="dateOnClick(index)">
          <date-input
            :title="item.subjectContent"
            :value="item.subjectResult"
            placeholder="请输入日期"
            :index="index"
            :isRequred="item.isRequired"
          />
        </div>
        <div class="height24"></div>
      </div>
    </div>

    <div class="height80"></div>
    <div class="height80"></div>
    <div class="height80"></div>
    <div class="g-safe-area-bottom"></div>
    <submit-btn
      @click="sumbitClick"
      style="position:fixed;bottom:0;left:0;"
      :title="this.agreeStatus != 2 ? '提交' : '返回'"
    />

    <van-popup v-model="showDatePicker" position="bottom"
      ><van-datetime-picker
        type="date"
        title="选择年月日"
        :minDate="minDate"
        :maxDate="maxDate"
        v-model="currentDate"
        :show-toolbar="true"
        @confirm="datePickerOnConfirm"
        @cancel="datePickerOnCancel"
        :formatter="formatter"
      >
      </van-datetime-picker
    ></van-popup>
    <van-popup v-model="showAreaPicker" position="bottom">
      <van-area
        title="请选择地区"
        :area-list="areaList"
        @confirm="areaPickerOnConfirm"
        @cancel="areaPickerOnCancel"
    /></van-popup>
  </div>
</template>

<script>
import SectionView from "@/components/section-view.vue";
import SubmitBtn from "@/components/submit-btn.vue";
import TextInput from "@/components/input/text-input.vue";
import RadioInput from "@/components/input/radio-input.vue";
import DateInput from "@/components/input/date-input.vue";
import LocationInput from "../../components/input/location-input.vue";
import CheckboxInput from "../../components/input/checkbox-input.vue";
import { DatetimePicker, Popup, Area, Dialog, Toast } from "vant";
import { areaList } from "@vant/area-data";
export default {
  components: {
    SubmitBtn,
    SectionView,
    TextInput,
    RadioInput,
    DateInput,
    LocationInput,
    CheckboxInput,
    DatetimePicker,
    Popup,
    Area,
  },
  data() {
    return {
      questionList: [],
      areaList,
      showDatePicker: false,
      showAreaPicker: false,
      currentDatePickerIndex: 0,
      currentAreaPickerIndex: 0,
      isBirthdayPicker: false,
      isCurrentAreaPicker: false,
      agreeStatus: -1,
      name: "", //string	非必须 姓名
      phone: "", //string	非必须 手机号
      family_phone: "", //string	非必须 家属手机号
      sex: "", //string	非必须 性别（男，女）
      birthday: "", //string	非必须 生日 yyyy-mm-dd
      areaAddress: "", //string	非必须地址
      minDate: new Date(1920, 1, 1),
      maxDate: new Date(2025, 10, 1),
      currentDate: new Date(),
    };
  },
  beforeCreate() {
    document.querySelector("body").setAttribute("style", "background:#F7F9FC");
  },
  beforeDestroy() {
    document.querySelector("body").setAttribute("style", "");
  },
  mounted() {
    this.getDetailRequest();
    this.scrollToTop();
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    isInvalidString(str) {
      return str == null || str.length <= 0;
    },
    isValidPhoneNumber(phone) {
      if (this.isInvalidString(phone)) {
        return false;
      }
      if (phone.length !== 11) {
        return false;
      }
      if (phone[0] != "1") {
        return false;
      }
      return true;
    },
    formatter(type, val) {
      if (type === "month") {
        return `${val}月`;
      }
      if (type === "day") {
        return `${val}日`;
      }
      return val;
    },
    timeFormat(time) {
      let year = time.getFullYear();
      let month = time.getMonth() + 1;
      let day = time.getDate();
      if (month < 10) {
        month = "0" + month;
      }
      if (day < 10) {
        day = "0" + day;
      }

      return year + "-" + month + "-" + day;
    },

    //同意,不同意
    agreeRadioOnChange(e) {
      // {"value":[2]}
      if (e.value && e.value.length > 0) {
        let id = e.value[0];
        this.agreeStatus = id;
      }
    },

    //性别
    genderRadioOnChange(e) {
      if (e.value && e.value.length > 0) {
        let id = e.value[0];
        this.sex = id;
      }
    },

    //填空
    textOnChange(e) {
      //姓名
      if (e.field == "name") {
        this.name = e.value;
        return;
      }

      //电话
      else if (e.field == "phone") {
        this.phone = e.value;
        return;
      }

      //家属电话
      else if (e.field == "family_phone") {
        this.family_phone = e.value;
        return;
      }
      console.log(`textOnChange: ${JSON.stringify(e)}`);
      this.questionList[e.index].subjectResult = e.value;
    },

    //出生日期
    birthdayOnClick(e) {
      this.showDatePicker = true;
      this.isBirthdayPicker = true;
    },

    //所在省份和地区
    currentAreaOnClick(e) {
      this.showAreaPicker = true;
      this.isCurrentAreaPicker = true;
    },

    //地区
    areaOnClick(index) {
      this.showAreaPicker = true;
      this.currentAreaPickerIndex = index;
    },
    areaPickerOnConfirm(e) {
      console.log(`${JSON.stringify(e)}`);
      this.showAreaPicker = false;
      if (this.isCurrentAreaPicker == true) {
        this.isCurrentAreaPicker = false;
        this.areaAddress = e
          .map((item) => {
            return item.name;
          })
          .join(" ");
        return;
      }

      this.questionList[this.currentAreaPickerIndex].subjectResult = e
        .map((item) => {
          return item.name;
        })
        .join(" ");
    },
    areaPickerOnCancel(e) {
      this.showAreaPicker = false;
    },

    //日期
    dateOnClick(index) {
      this.showDatePicker = true;
      this.currentDatePickerIndex = index;
    },
    datePickerOnConfirm(e) {
      console.log(`${JSON.stringify(e)}`);
      this.showDatePicker = false;
      if (this.isBirthdayPicker == true) {
        this.isBirthdayPicker = false;
        this.birthday = this.timeFormat(e);
        return;
      }
      this.questionList[
        this.currentDatePickerIndex
      ].subjectResult = this.timeFormat(e);
    },
    datePickerOnCancel(e) {
      this.showDatePicker = false;
    },

    //单选
    radioOnChange(e) {
      console.log(`radioOnChange: ${JSON.stringify(e)}`);
      const subjectOptions = this.questionList[e.index].subjectOptions;

      let strList = [];
      for (let select of e.value) {
        for (let item of subjectOptions) {
          if (select == item.id) {
            strList.push(item.optionContent);
          }
        }
      }

      this.questionList[e.index].subjectOptionIds = JSON.stringify(e.value);
      this.questionList[e.index].subjectResult = strList.join(",");
    },

    //多选
    checkboxOnChange(e) {
      console.log(`checkboxOnChange: ${JSON.stringify(e)}`);

      const subjectOptions = this.questionList[e.index].subjectOptions;

      let strList = [];
      console.log(`e.value: ${JSON.stringify(e.value)}`);

      for (let select of e.value) {
        for (let item of subjectOptions) {
          if (select == item.id) {
            strList.push(item.optionContent);
          }
        }
      }

      this.questionList[e.index].subjectOptionIds = JSON.stringify(e.value);
      this.questionList[e.index].subjectResult = strList.join(",");
    },

    //地区
    locationOnChange(e) {
      console.log(`locationOnChange: ${JSON.stringify(e)}`);
      this.questionList[e.index].subjectResult = e.value;
    },

    //获取详情信息
    getDetailRequest() {
      this.$axios
        .get(
          `/questionnaire/get/subjectAndOptionList/byQuestionnaireId?questionnaireId=${this.$route.query.questionnaireId}`
        )
        .then((res) => {
          this.questionList = res.data;
        });
    },

    //提交请求
    submitReuqest() {
      // 名称	类型	是否必须	默认值	备注	其他信息
      // name	string 非必须 姓名
      // phone	string非必须手机号
      // family_phone	string非必须家属手机号
      // sex	string非必须性别（男，女）
      // birthday	string非必须生日 yyyy-mm-dd
      // areaAddress	string非必须地址
      // clinicalRecruitmentId	string非必须临床招募id
      // sourceChannel	string非必须来源 mini,app

      // subject	object []非必须已填 题目和选项
      // item 类型: object
      // subjectId	string必须题目id
      // subjectResult	string必须题目答案  英文逗号间隔，例："苹果,香蕉,橘子"，填空返回字符串
      // subjectOptionIds	string必须所选选项，json，例：[21,25,24,26]

      let submitData = {
        name: this.name,
        phone: this.phone,
        family_phone: this.family_phone,
        sex: this.sex == 1 ? "男" : "女",
        birthday: this.birthday,
        areaAddress: this.areaAddress,
        clinicalRecruitmentId: this.$route.query.id, //	string非必须临床招募id
        sourceChannel: "mini", //	string非必须来源 mini,app
        subject: this.questionList
          .filter((item) => {
            // 保留必填项和已填项
            if (
              item.isRequired == true ||
              !this.isInvalidString(item.subjectResult)
            ) {
              return true;
            }
            return false;
          })
          .map((item) => {
            return {
              subjectId: item.subjectId,
              subjectResult: item.subjectResult,
              subjectOptionIds: item.subjectOptionIds,
            };
          }),
      };
      this.$axios.get(`/get/request/token`).then((res) => {
        this.$axios
          .post(`/recruitment/record/submit?reqToken=${res.data}`, {
            ...submitData,
          })
          .then((res) => {
            Dialog.alert({
              title: "",
              confirmButtonColor: "#12BEAE",
              message:
                "感谢您的参与。我们将在1-3个工作日内与您联系，请您保持电话畅通",
            })
              .then(() => {
                // this.$router.push({
                //   path: "/clinicalVolunteer/projects",
                // });

                //back to wechat
                this.goBackToRoot();
                // let environment = "";
                // var ua = navigator.userAgent.toLowerCase();
                // if (ua.match(/MicroMessenger/i) == "micromessenger") {
                //   //ios的ua中无miniProgram，但都有MicroMessenger（表示是微信浏览器）
                //   wx.miniProgram.getEnv((res) => {
                //     if (res.miniprogram) {
                //       environment = "miniprogram";
                //     }
                //   });
                // }
                // environment = environment || this.$route.query.platform;
                // if (environment == "miniprogram") {
                //   wx.miniProgram.switchTab({
                //     url: `/pages/home/home`,
                //   });
                // }
              })
              .catch(() => {
                this.$router.go(-1);
              });
          });
      });
    },

    //提交
    sumbitClick() {
      if (this.agreeStatus == -1) {
        this.$toast("请选择是否同意或不同意");

        return;
      } else if (this.agreeStatus == 2) {
        //back to wechat
        console.log(`返回首页`);
        this.goBackToRoot();
        return;
      }

      if (this.isInvalidString(this.name)) {
        this.$toast("请输入姓名");
        return;
      } else if (this.isInvalidString(this.phone)) {
        this.$toast("请输入手机号码");
        return;
        // } else if (this.isInvalidString(this.family_phone)) {
        //   this.$toast("请输入家属的手机号码");
        //   return;
      } else if (this.isInvalidString(this.sex)) {
        this.$toast("请选择性别");
        return;
      } else if (this.isInvalidString(this.birthday)) {
        this.$toast("请选择出生日期");
        return;
      } else if (this.isInvalidString(this.areaAddress)) {
        this.$toast("请选择所在省份和地区");
        return;
      }

      //自定义题目的必选提示
      debugger;
      if (this.questionList && this.questionList.length) {
        for (let item of this.questionList) {
          if (
            item.isRequired == true &&
            (item.subjectResult == null || item.subjectResult.length <= 0)
          ) {
            // <!-- subjectType null 类型 0-单选,1-多选,2-填空 地区3 日期4 -->
            if (item.subjectType == 2) {
              this.$toast("请输入" + item.subjectContent);
              return;
            } else {
              this.$toast("请选择" + item.subjectContent);
              return;
            }
          }
        }
      }

      //手机号校验
      if (!this.isValidPhoneNumber(this.phone)) {
        this.$toast("请输入正确的手机号码");
        return;
      }
      if (
        !this.isInvalidString(this.family_phone) &&
        !this.isValidPhoneNumber(this.family_phone)
      ) {
        this.$toast("请输入正确的家属手机号码");
        return;
      }

      //提交请求
      this.submitReuqest();
    },
    goBackToRoot() {
      this.$router.go(-1);
      // if (window.history.length > 2) {
      //   let deepth = window.history.length - 1;
      //   this.$router.go(-deepth);
      // } else {
      //   // this.$router.go(-1);
      // }
    },
  },
};
</script>

<style lang="scss" scoped>
.v-project-join {
  background: #f7f9fc;
  .notice-wraper {
    padding: 40px 28px;
    .notice {
      white-space: normal;
      text-align: justify;
      word-break: break-all;
      font-size: 28px;
      font-weight: 400;
      color: #4f5265;
      line-height: 40px;
      a {
        color: #12beae;
      }
    }
  }
}
</style>
